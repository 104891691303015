export default {
  page: {
    title: {
      base: 'Bramka płatnicza Autopay',
      start: 'Wybór metody płatności',
      visadata: 'Płatność VisaMobile',
      continue: 'Kontynuacja - Wybór metody płatności',
      wait: 'Oczekiwanie',
      confirmation: 'Potwierdzenie płatności',
      thankYou: 'Podziękowanie za płatność',
      error: 'Błąd strony',
      errorNotFound: 'Strona nie znaleziona',
      secureBack: 'Ładowanie strony',
      maintenance: 'Strona w konserwacji',
      regulationsApproval: 'Regulamin i Polityka Prywatności',
      toTransferData: 'dane do przelewu',
      sessionTimeout: 'Strona po zakończeniu sesji',
      paywayError: 'Niedostępny kanał płatności'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Zwiększ rozmiar czcionki',
      decreaseFontSize: 'Zmnniejsz rozmiar czcionki',
      changeContrast: 'Zmień kontrast',
      changeLanguage: 'Zmień język strony',
      skipToContent: 'Przeskocz do treści',
      skipToContentLabel: 'Przejdź do głównej treści',
      logoTitle: 'Logo witryny'
    },
    sessionTimer: {
      timeOut: 'Sesja wygasła',
      sessionEnd: 'Koniec sesji za:',
      label: 'Przedłuż czas sesji',
      button: 'Przedłuż'
    }
  },
  footer: {
    text1: 'Masz dodatkowe pytania związane z płatnościami online lub innymi naszymi usługami? <a title="Otwiera się w nowym oknie" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" rel="noreferrer">Przejdź do strony Pomocy </a>',
    text2: {
      part1: 'Administratorem danych osobowych jest Autopay S.A.',
      part2: 'ul. Powstańców Warszawy 6, 81-718 Sopot. Podanie danych osobowych jest dobrowolne, ale niezbędne do realizacji przesłanego zgłoszenia. Podstawa prawna, cel, okres przetwarzania danych osobowych oraz uprawnienia przysługujące Użytkownikowi, a także inne ważne informacje dotyczące zasad przetwarzania danych osobowych są szczegółowo określone w <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Przejdź do strony z dokumentem Polityki prywatności (128 KB, PDF)" rel="noreferrer">polityce prywatności Autopay S.A.</a>'
    },
    linkShowMoreTitle: 'Wyświetl dodatkowe informacje',
    linkShowMoreText: 'Czytaj więcej',
    linkShowLessTitle: 'Ukryj dodatkowe informacje',
    linkShowLessText: 'Czytaj mniej'
  },
  paywall: {
    header: 'Wybierz metodę płatności',
    info: {
      paywayNotAvailable: 'Bardzo nam przykro, ale wygląda na to, że wybrana metoda płatności nie jest możliwa w tym momencie na Twoim urządzeniu.',
      applePayNotAvailable: 'Bardzo nam przykro, ale wygląda na to, że płatność za pomocą Apple Pay nie jest możliwa w tym momencie na Twoim urządzeniu.',
      googlePayNotAvailable: 'Bardzo nam przykro, ale wygląda na to, że płatność za pomocą Google Pay nie jest możliwa w tym momencie na Twoim urządzeniu.'
    },
    paywayGroup: {
      inactiveMessage: 'Płatność jest obecnie niedostępna',
      notice: {
        novelty: 'NOWOŚĆ',
        promotion: 'PROMOCJA',
        recommended: 'POLECANE',
        mastercard: 'SZYBKO I BEZPIECZNIE',
        mastercardC2P: 'SZYBKO I BEZPIECZNIE',
        blik: 'BLIKOMANIA – zarejestruj się i wygraj diamenty!',
        visaMobile: 'Płać gładko i bezpiecznie'
      },
      bank: {
        many: 'banków',
        one: 'bank',
        two: 'banki'
      },
      wallet: {
        many: 'portfeli',
        one: 'portfel',
        two: 'portfele'
      },
      otp: {
        many: 'sposobów',
        one: 'sposób',
        two: 'sposoby'
      },
      blikPayLater: {
        info: 'Dowiedz się więcej'
      }
    },
    paywayList: {
      label: 'Grupa rodzajów płatności',
      linkSelectOther: 'Wybierz inny'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Mam konto w innym banku'
    },
    paywaySwitch: {
      methodShortSingle: 'Jednorazowa',
      methodLongSingle: 'Płatności jednorazowe',
      methodShortAuto: 'Cykliczna',
      methodLongAuto: 'Płatności cykliczne'
    },
    form: {
      payway: {
        label: 'Wybierz płatność'
      },
      card: {
        label: 'Podaj dane karty',
        iframe: {
          title: 'Formularz z danymi karty kredytowej do uzupełnienia'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Formularz z numerem telefonu do uzupełnienia'
        },
        status: {
          pending: {
            statusText: 'Oczekiwanie na potwierdzenie',
            header: 'Potwierdź płatność w aplikacji',
            descriptionText1: 'Płatność potwierdzisz w aplikacji Visa&nbsp;Mobile lub aplikacji bankowej z aktywną usługą Visa&nbsp;Mobile',
            descriptionText2: 'Po potwierdzeniu transakcji w aplikacji, oczekuj na wynik płatności',
            descriptionText3: 'Trwa anulowanie transakcji',
            cancelButton: 'Anuluj płatność'
          },
          success: {
            statusText: 'Dziękujemy za dokonanie zakupu!',
            descriptionText: 'Płatność zakończona pomyślnie'
          },
          error: {
            statusText: 'Upewnij się, że masz wystarczające środki na karcie płatniczej użytej do płatności Visa&nbsp;Mobile.',
            descriptionText: 'Płatność odrzucona'
          }
        },
        apps: 'Aplikacje obsługujące płatności Visa&nbsp;Mobile',
        help: 'Potrzebujesz pomocy?',
        info: 'Dowiedz się więcej',
        secure: 'Bezpieczna płatność dzięki szyfrowaniu SSL',
        modal2: {
          header: 'Aby potwierdzić płatność otwórz aplikację Visa&nbsp;Mobile lub aplikację banku, w której masz aktywną usługę Visa&nbsp;Mobile',
          listText1: 'masz zainstalowaną aplikację Visa&nbsp;Mobile lub aktywną usługę Visa&nbsp;Mobile w aplikacji mobilnej swojego banku',
          listText2: 'masz włączone powiadomienia w aplikacji Visa&nbsp;Mobile lub aplikacji bankowej z aktywną usługą Visa&nbsp;Mobile',
          listText3: 'podany przez Ciebie numer jest prawidłowy: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Upewnij się również, że...'
        }
      },
      click2pay: {
        loaderText: 'Szukamy Twojego adresu e-mail<br>w <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Zapisz swoją kartę',
          text2: 'Twoje dane zostaną bezpiecznie zapisane w <strong>Mastercard Click to Pay</strong>',
          link: 'Dowiedz się więcej'
        },
        email: {
          labelForClick2Pay: 'Adres e-mail do powiadomień o płatności'
        },
        cardSelect: {
          selected: 'Wybrana',
          select: 'Wybierz',
          payOther: 'Wpisz dane karty',
          back: 'Powrót'
        },
        cardSelected: {
          header: 'Użyj karty zapisanej w Click to Pay',
          date: 'ważna do',
          buttonChange: 'Zmień'
        },
        codeForm: {
          description: 'Znaleźliśmy Twój adres e-mail w Click to Pay.',
          codeSentEmail: 'Wpisz jednorazowy kod, który wysłaliśmy na adres {email} w celu zalogowania się na konto.',
          codeSentPhone: 'Wpisz jednorazowy kod SMS, który wysłaliśmy na nr {phone} w celu zalogowania się na konto.',
          codeLabel: 'Podaj kod',
          codePlaceholder: 'podaj kod',
          codeLabelFlow: 'Kod',
          codeSendLabel: 'Wyślij nowy kod',
          codeSendAriaLabel: 'przez',
          codeSendAriaLabel2: 'lub',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'e-mail',
          trustedLabel: 'Dodaj to urządzenie do zaufanych<br><span>Następnym razem rozpoznamy to urządzenie, a Ty zapłacisz bez logowania</span>',
          buttonSubmit: 'Dalej',
          buttonChangeCard: 'Wprowadź dane karty'
        },
        info: {
          description: 'Płatność kartą jest zaszyfrowana i bezpieczna. Transakcja zostanie autoryzowana za pomocą 3DSecure na stronie banku.'
        },
        loginForm: {
          emailLabel: 'Podaj adres e-mail, aby zalogować się do Click to Pay',
          emailPlaceholder: 'adres e-mail',
          emailLabelFlow: 'E-mail',
          buttonSubmit: 'Zaloguj się',
          buttonBack: 'Powrót'
        },
        newUserForm: {
          header: 'Zapisz kartę w Click to Pay',
          description: 'Potrzebujemy jeszcze kilku informacji, by zapisać Twoją kartę.',
          emailLabel: 'Podaj adres e-mail',
          emailPlaceholder: 'adres e-mail',
          emailLabelFlow: 'E-mail',
          countryLabel: 'Kraj',
          firstnameLabel: 'Imię',
          firstnamePlaceholder: 'imię',
          firstnameLabelFlow: 'Imię',
          lastnameLabel: 'Nazwisko',
          lastnamePlaceholder: 'nazwisko',
          lastnameLabelFlow: 'Nazwisko',
          phoneLabel: 'Numer telefonu',
          phonePlaceholder: 'numer telefonu',
          phoneLabelFlow: 'Telefon',
          countryCodePlaceholder: 'kod',
          countryCodeLabelFlow: 'Kod',
          trustedLabel: 'Dodaj to urządzenie do zaufanych<br><span>Następnym razem rozpoznamy to urządzenie, a Ty zapłacisz bez logowania</span>',
          termsLabel: 'Kontynuując, akceptujesz <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Warunki korzystania ze strony</a> i&nbsp;rozumiesz, że Twoje dane będą przetwarzane zgodnie z <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Polityką prywatności</a> Mastercard.',
          c2pInfo: 'Click to Pay użyje tych informacji, aby Cię zweryfikować i wysyłać na ten numer kody weryfikacyjne. Mogą zostać naliczone opłaty za wiadomości/transmisję danych.',
          buttonSubmit: 'Zapisz i zapłać',
          buttonBack: 'Zapłać bez zapisywania karty'
        },
        profile: {
          header: 'Zaloguj się do Click to Pay',
          buttonChangeEmail: 'Zmień adres e-mail'
        },
        modal: {
          title: 'Click to Pay - informacje',
          header: 'Płać szybko i bezpiecznie, bez przepisywania danych karty w nowym standardzie Click to Pay',
          text1: 'Podczas płatności kartą szukaj logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> i płać kartą płatniczą szybko i wygodnie.',
          text2: 'Click To Pay to nowy standard płatności kartą wspierany przez Visa i Mastercard.',
          text3: 'Kartą na koncie Click to Pay zapłacisz w sklepach w Polsce i za granicą, bez przepisywania danych z karty.',
          text4: 'Dane zapisywane są bezpiecznie w Visa lub Mastercard. Wszystkie informacje są w pełni szyfrowane.',
          text5: 'Podczas kolejnych płatności Click to Pay rozpozna Twój adres e-mail, dzięki czemu od razu zapłacisz zapisanymi kartami.',
          text6: 'Płatności potwierdzasz dodatkowo w swoim banku, co gwarantuje pełne bezpieczeństwo.',
          processError: 'W trakcie wykonywania płatności Click to Pay wystąpił błąd. Wykonaj standardową płatność kartą.'
        }
      },
      email: {
        label: 'Podaj adres e-mail',
        flowLabel: 'E-mail',
        tip: 'Potrzebujemy Twojego adresu e-mail, żeby móc wysyłać wiadomości dotyczące statusu płatności.'
      },
      terms: {
        header: 'Zgody i oświadczenia',
        selectAll: 'Zaznaczam wszystkie',
        fieldNotRequired: 'Pole nieobowiązkowe',
        partnerMarketingTextLess: 'Czy chcesz otrzymywać od nas mailowe, SMSowe lub telefoniczne powiadomienia o najlepszych ofertach, promocjach, konkursach i innych ciekawych wydarzeniach organizowanych przez nas lub naszych Partnerów?',
        partnerMarketingTextMore: 'Obiecujemy nie spamować! Jeżeli nasze wiadomości Ciebie znudzą, w każdej chwili możesz wycofać zgodę.',
        tradeMarketingTextLess: 'Czy chcesz abyśmy mogli przekazać naszym zaufanym Partnerom Twój email lub telefon, aby mogli Tobie przedstawiać ciekawe informacje i oferty?',
        tradeMarketingTextMore: 'Zapewniamy, że grono naszych Partnerów to uznane firmy, które tak jak my nie lubią spamować, a zgodę możesz w każdej chwili odwołać.'
      },
      termsLabelDefault: 'Zapoznałem się i akceptuję <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Pobierz plik z regulaminem usługi" rel="noreferrer">Regulamin</a> świadczenia usług płatniczych oraz <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Pobierz plik z polityką prywatności Autopay S.A.">Politykę prywatności</a>. Chcę aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane, na moje żądanie, do chwili odstąpienia od umowy.',
      termsReccuring: 'Jeśli chcesz wykonać płatność, zaakceptuj <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Pobierz plik z regulaminem usługi" rel="noreferrer">Regulamin</a> i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Pobierz plik z polityką prywatności Autopay S.A.">Politykę Prywatności</a> Autopay S.A., zgodę na natychmiastową realizację usługi płatniczej oraz zgodę na obciążanie mojej karty płatniczej',
      termsReccuringMore1: 'Zgadzam się aby usługa została zrealizowana niezwłocznie, a w przypadku odstąpienia od umowy, wiem, że nie otrzymam zwrotu poniesionych kosztów za usługi zrealizowane na moje żądanie do chwili odstąpienia od umowy.',
      termsReccuringMore2: 'Zgadzam się na cykliczne obciążanie przez Autopay S.A. mojej karty płatniczej w celu realizacji transakcji na rzecz {receiverName}. Zasady cyklicznego obciążania karty określa <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Pobierz plik z regulaminem usługi" rel="noreferrer">Regulamin</a> świadczenia usług płatniczych. Oświadczam, że są mi znane terminy, częstotliwość i zasady ustalania kwot obciążeń mojej karty płatniczej, a także sposób odwołania niniejszej zgody, które zostały przeze mnie ustalone z {receiverName}. W razie pytań związanych z cyklicznym obciążaniem karty płatniczej będę kontaktować się z {receiverName}.',
      mwfClauseLess: 'Jeśli chcesz wykonać płatność, zaakceptuj <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Pobierz plik z regulaminem usługi" rel="noreferrer">Regulamin</a> i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Pobierz plik z polityką prywatności Autopay S.A.">politykę prywatności Autopay S.A.</a>, zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Autopay S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz żądanie natychmiastowej realizacji usługi płatniczej.',
      mwfClauseMore: 'Chcę, aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy. ',
      mwfClauseARIA: 'Jeśli chcesz wykonać płatność, zaakceptuj Regulamin i politykę prywatności Autopay S.A., zgodę na rozpoczęcie transakcji w Twoim banku za pośrednictwem Autopay S.A. i dostarczenie szczegółów tej transakcji do odbiorcy oraz żądanie natychmiastowej realizacji usługi płatniczej. Chcę, aby usługa została zrealizowana niezwłocznie i wiem, że nie otrzymam zwrotu poniesionych kosztów w przypadku odstąpienia od umowy. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Pobierz plik z regulaminem usługi</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Pobierz plik z polityka prywatności Autopay S.A.</a>',
      paymentInfo: 'Zlecenie płatnicze jest składane za pośrednictwem Autopay S.A. z siedzibą w  Sopocie i zostanie zrealizowane zgodnie z warunkami określonymi przez Twój bank. Po&nbsp;wyborze banku dokonasz autoryzacji płatności.',
      changePaywayLink1: 'Ciągle jeszcze możesz',
      changePaywayLink2: 'zmienić formę płatności',
      linkMore: 'WIĘCEJ',
      linkShowMoreTitle: 'Wyświetl dodatkowe informacje',
      linkLess: 'MNIEJ',
      linkShowLessTitle: 'Ukryj dodatkowe informacje',
      submitButton: {
        visaMobile: 'Zapłać przez',
        fastTransfer: 'Wygeneruj dane do przelewu',
        mwf: 'Akceptuję i płacę',
        pis: 'Akceptuję i rozpoczynam płatność',
        auto: 'Zleć płatność cykliczną',
        autoPayment: 'Zleć płatność',
        other: 'Płacę',
        orange: 'Potwierdź',
        c2pNext: 'Dalej',
        c2pAccept: 'Akceptuję',
        c2pSaveAndPay: 'Zapisz i zapłać'
      }
    },
    frData: {
      pageTitle: 'Szczegółowe dane do przelewu',
      header: 'Wykonaj przelew wykorzystując dane',
      nrbLabel: 'Rachunek odbiorcy',
      nameLabel: 'Nazwa odbiorcy',
      addressLabel: 'Adres odbiorcy',
      titleLabel: 'Tytuł przelewu',
      amountLabel: 'Kwota przelewu',
      timeLabel: 'Transakcję wykonaj do',
      linkCopy: 'Kopiuj',
      linkGetPdf: 'Pobierz PDF z danymi',
      linkGetPdfTitle: 'Otwiera się w nowym oknie',
      linkGoToBank: 'Zaloguj się do banku',
      info: 'Zaloguj się do swojego konta w banku. Skopiuj dane w przelew. Ważne, żeby dane były takie same, nie zmieniaj kwoty, waluty, tytułu, itd.'
    },
    processing: {
      pageTitle: 'Strona oczekiwania na przetworzenie transakcji',
      loaderAnimation: 'Animacja wyświetlana w czasie oczekiwania',
      header: 'Przygotowujemy Twoją stronę płatności.',
      description: 'Poczekaj jeszcze chwilę...',
      info: {
        header: 'Pamiętaj!',
        description: 'Po zakończeniu transakcji nie zamykaj strony - jeśli to zrobisz Twoje pieniądze nie zostaną przesłane do odbiorcy.'
      },
      loaderText: 'Trwa przesyłanie danych.<br>Zajmie nam to maksymalnie 30 sekund.'
    }
  },
  thankYou: {
    pageTitle: 'Informacja o wyniku transakcji',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Wróć na stronę partnera'
    },
    negative: {
      header: 'Płatność nie została zrealizowana z powodu braku autoryzacji transakcji.',
      description: 'Jeżeli wciąż chcesz skorzystać z oferty danego sprzedawcy lub usługodawcy - wróć na jego stronę www, wybierz produkt lub usługę i dokonaj opłaty korzystając z wybranej przez Ciebie metody.'
    },
    pending: {
      header: 'Dziękujemy za zlecenie płatności za pośrednictwem bramki płatniczej.',
      description: 'Trwa weryfikacja statusu transakcji. Gdy proces się zakończy, otrzymasz informację o statusie płatności na swoją skrzynkę mailową.'
    },
    positive: {
      header: 'Dziękujemy, Twoja płatność została zrealizowana.',
      description: 'Potwierdzenie wyślemy na podany przez Ciebie adres mailowy.'
    }
  },
  regulationsApproval: {
    header: 'Zaakceptuj transakcję'
  },
  wait: {
    pageTitle: 'Strona oczekiwania'
  },
  sessionTimeout: {
    pageTitle: 'Strona po zakończeniu sesji',
    header: 'Czas na dokończenie płatności minął.',
    description: 'Możesz w każdej chwili wrócić na stronę partnera, ponownie złożyć zamówienie lub skorzystać z usługi i dokonać opłaty korzystając z wybranej przez Ciebie metody.'
  },
  paywayError: {
    pageTitle: 'Wybrany kanał płatności jest niedostępny',
    header: 'Niestety wybrana metoda płatności  jest w tej chwili niedostępna.',
    description: 'Wróć na stronę partnera i spróbuj ponownie później.'
  },
  loader: {
    defaultAnimation: 'Animacja wyświetlana w czasie ładowania strony',
    slider: 'Slider ze zmieniającymi się tekstami',
    text1: 'W Autopay dążymy do neutralności węglowej. Też&nbsp;to&nbsp;robisz korzystając z naszych usług.',
    text2: 'W Autopay jeździmy rowerami do pracy ograniczając emisję gazów cieplarnianych do atmosfery. Też to robisz korzystając z naszych usług.',
    text3: 'W Autopay zasila nas zielona energia.<br>Ciebie również, gdy korzystasz z naszych usług.',
    text4: 'W Autopay ograniczamy zużycie papieru. Też&nbsp;to&nbsp;robisz korzystając z naszych usług.',
    rateLimitExceeded: 'Trwa wzmożony ruch transakcyjny, dlatego procesowanie Twojej płatności może potrwać dłużej niż zwykle. Proszę zaczekaj i nie odświeżaj strony płatności.'
  },
  orderDetails: {
    header: 'Szczegóły',
    headerAriaLabel: 'Szczegóły transakcji',
    amountLabel: 'Kwota należności',
    receiverLabel: 'Odbiorca',
    orderLabel: 'Numer zamówienia',
    amountOryginalCurrencyLabel: 'Kwota w {currency}',
    currencyRateLabel: 'Kurs waluty',
    feeLabel: 'Opłata za realizację transakcji',
    customerNumberLabel: 'Numer Klienta',
    invoiceNumberLabel: 'Numer faktury',
    accountNumberLabel: 'Numer konta',
    paywayLabel: 'Metoda płatności',
    paywayName: 'Przelew internetowy',
    linkBack: 'Powrót do sklepu',
    linkBackTitle: 'Opuść transakcję i wróć do sklepu',
    linkMore: 'więcej',
    linkLess: 'mniej',
    toggleBtnUnfoldAriaLabel: 'Rozwiń szczegóły zamówienia',
    toggleBtnFoldAriaLabel: 'Zwiń szczegóły zamówienia'
  },
  error: {
    pageTitle: 'Strona z informacją o błędzie',
    linkBackUrlSet: 'Wróć na stronę partnera',
    linkBackUrlNotSet: 'Przejdź na stronę pomocy',
    sessionTimeout: {
      title: 'Czas na dokończenie płatności minął',
      message: 'Możesz w każdej chwili wrócić na stronę partnera, ponownie złożyć zamówienie lub skorzystać z usługi i dokonać opłaty korzystając z wybranej przez Ciebie metody.'
    },
    notFoundError: {
      pageTitle: 'Strona z informacją o błędzie 404 - strona nie została znaleziona',
      title: 'Wygląda na to, że popłynąłeś za daleko i nie udało się odnaleźć strony, którą chcesz odwiedzić.',
      message: 'Możliwe przyczyny: strona nie istnieje lub została usunięta, trwają prace serwisowe, adres strony jest błędny, wystąpił błąd techniczny.'
    },
    generalError: {
      title: 'Przepraszamy',
      message: 'W tej chwili nie możemy zrealizować Twojego przelewu.'
    },
    errFieldNotFound: {
      title: 'Przepraszamy',
      message: ''
    },
    errBadClientSource: {
      title: 'Przepraszamy',
      message: ''
    },
    nrParametersError: {
      title: 'Przepraszamy',
      message: 'W tej chwili nie możemy zrealizować Twojego przelewu.'
    },
    transactionOutdated: {
      title: 'Czas na dokończenie płatności minął.',
      message: 'Zleć ją ponownie.'
    },
    linkValidityTimeOutdated: {
      title: 'Czas na dokończenie płatności minął.',
      message: 'Zleć ją ponownie.'
    },
    transactionValidityTimeOutdated: {
      title: 'Czas na dokończenie płatności minął.',
      message: 'Zleć ją ponownie.'
    },
    multiplyTransaction: {
      title: 'Przepraszamy',
      message: 'Transakcja już istnieje i oczekuje na wpłatę.'
    },
    transactionCanceled: {
      title: 'Przepraszamy',
      message1: 'Transakcja została anulowana.',
      message2: 'Zleć ją ponownie.'
    },
    multiplyPaidTransaction: {
      title: 'Przepraszamy',
      message: 'Transakcja jest już opłacona.'
    },
    transactionReject: {
      title: 'Przepraszamy',
      message1: 'Ups, coś poszło nie tak.',
      message2: 'Już naprawiamy bramkę...'
    },
    bankDisabled: {
      title: 'Przepraszamy',
      message: 'Wybrany bank jest w tej chwili niedostępny.'
    },
    bankTemporaryMaintenance: {
      title: 'Przepraszamy',
      message: 'W tej chwili nie możemy zrealizować Twojego przelewu.'
    },
    insufficientStartAmount: {
      title: 'Przepraszamy',
      message: 'Limit kwoty przelewu został przekroczony.'
    },
    startAmountOutOfRange: {
      title: 'Przepraszamy',
      message: 'Limit kwoty przelewu został przekroczony.'
    },
    nonAccountedLimitExceeded: {
      title: 'Przepraszamy',
      message: 'Płatność nie może być zrealizowana, ponieważ sprzedawca osiągnął miesięczny limit sprzedaży.'
    }
  },
  confirmation: {
    pageTitle: 'Informacja o przebiegu płatności',
    linkBack: 'Wracam na stronę',
    linkBackTimeout: 'Powrót na stronę partnera za ',
    incorrectAmount: {
      header: 'Błędna kwota',
      description: 'Nie przekazaliśmy Twojej płatności do odbiorcy, bo kwota się nie zgadzała.',
      description2: 'Kliknij przycisk, żeby wrócić na stronę i wykonać ją od nowa. Pamiętaj, żeby nie zmieniać żadnych danych.'
    },
    multiplePaid: {
      header: 'Podwójna wpłata',
      description: 'Odbiorca otrzymał Twoją płatność dwukrotnie. Nie martw się – otrzymasz zwrot.',
      description2: 'Skontaktuj się z odbiorcą i przekaż mu potwierdzenie płatności.'
    },
    negative: {
      header: 'Brak wpłaty',
      description: 'W trakcie przetwarzania płatności wystąpił błąd. Jednak nic straconego.',
      description2: 'Kliknij przycisk, żeby wrócić na stronę i wykonać ją od nowa.'
    },
    notFound: {
      header: 'Brak wpłaty',
      description: 'W trakcie przetwarzania płatności wystąpił błąd. Jednak nic straconego.',
      description2: 'Kliknij przycisk, żeby wrócić na stronę i wykonać ją od nowa.'
    },
    outdated: {
      header: 'Koniec czasu',
      description: 'Nie przekazaliśmy Twojej płatności do odbiorcy.',
      description2: 'Kliknij przycisk, żeby wrócić na stronę i wykonać ją od nowa.'
    },
    outdatedPaidNotRefunded: {
      header: 'Koniec czasu',
      description: 'Nie przekazaliśmy Twojej płatności do odbiorcy. Jeżeli pieniądze zostały pobrane z Twojego konta – nie martw się, otrzymasz zwrot w ciągu kilku dni.',
      description2: 'Kliknij przycisk, żeby wrócić na stronę i wykonać ją od nowa.'
    },
    outdatedPaidRefunded: {
      header: 'Koniec czasu',
      description: 'Nie przekazaliśmy Twojej płatności do odbiorcy. Jeżeli pieniądze zostały pobrane z Twojego konta – nie martw się, już je zwróciliśmy.',
      description2: 'Kliknij przycisk, żeby wrócić na stronę i wykonać ją od nowa.'
    },
    pending: {
      header: 'W trakcie',
      description: 'Realizujemy Twoją płatność. Powiadomimy Cię, gdy dotrze do odbiorcy.'
    },
    positive: {
      header: 'Zapłacone',
      visaVideo: 'Film reklamowy odtwarzany po udanej transakcji',
      description: 'Przekazaliśmy Twoje środki odbiorcy.'
    }
  },
  messages: {
    accessForbidden: 'Brak dostępu',
    internalServerError: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
    validators: {
      required: 'Pole wymagane',
      pattern: 'Podaj prawidłową wartość',
      emailRequired: 'Podaj poprawny adres e-mail',
      emailNotValid: 'Podaj poprawny adres e-mail',
      emailNotRecognized: 'Podany e-mail nie jest zarejestrowany',
      emailIdentityLookup: 'Nie udało się zweryfikować adresu e-mail',
      emailLocked: 'Podany e-mail jest tymczasowo zablokowany w systemie Mastercard',
      paywayRequired: 'Aby zapłacić wybierz bank',
      creditCardRequired: 'Uzupełnij wszystkie pola',
      phoneRequired: 'Podaj poprawny numer telefonu',
      regulationRequired: 'Zaakceptuj regulamin',
      requiredConsent: 'Zgoda wymagana',
      codeNotValid: 'Podany kod nie jest prawidłowy',
      codeAccountLocked: 'Konto zostało tymczasowo zablokowane w systemie Mastercard'
    },
    validatorsAria: {
      required: 'Uwaga. Pole formularza nie zostało wypełnione. Pole wymagane.',
      pattern: 'Uwaga. Pole zawiera nieprawidłową wartość.',
      emailRequired: 'Uwaga. Pole email nie zostało wypełnione. Podaj poprawny adres e-mail.',
      emailNotValid: 'Uwaga. Twój adres e-mail zawiera błąd. Podaj poprawny adres e-mail.',
      emailNotRecognized: 'Uwaga. Podany e-mail nie jest zarejestrowany.',
      emailIdentityLookup: 'Uwaga. Nie udało się zweryfikować podanego adresu e-mail',
      emailLocked: 'Uwaga. Podany e-mail jest tymczasowo zablokowany w systemie Mastercard.',
      paywayRequired: 'Uwaga. Płatność nie została wybrana. Wybierz metodę płatności.',
      creditCardRequired: 'Uwaga. Uzupełnij wszystkie pola z danymi karty kredytowej.',
      phoneRequired: 'Uwaga. Pole numer telefonu zawiera błąd. Podaj poprawny numer telefonu.',
      codeNotValid: 'Uwaga. Podany kod nie jest prawidłowy.',
      codeAccountLocked: 'Uwaga. Konto zostało tymczasowo zablokowane w systemie Mastercard.'
    },
    success: {
      copy: 'Skopiowano!',
      codeSent: 'Kod został wysłany!'
    },
    errors: {
      getPdf: 'Nie udało sie pobrać pliku PDF!',
      copy: 'Nie udało się skopiować!',
      thankYouUpdate: 'Nie udało się zapisać adresu e-mail!',
      groupInactive: 'W tej chwili wykonanie przelewu przy pomocy grupy {groupName} jest niemożliwe. Wybierz inną metodę płatności.',
      paywayInactive: 'W tej chwili wykonanie przelewu z wybranego banku jest niemożliwe. Wybierz inny bank lub metodę płatności.',
      googlePayInactive: 'Niestety płatność Google Pay nie jest dostępna na Twoim urządzeniu.',
      applePayInactive: 'Płatność Apple Pay jest dostępna dla osób korzystających z przeglądarki Safari oraz urządzeń z systemem operacyjnym iOS.',
      codeNotSent: 'Nie udało się wysłać ponownie kodu!',
      codeCounterExceeded: 'Przekroczono dozwoloną liczbę prób. Wprowadź dane karty ręcznie lub skorzystaj z innej metody płatności.'
    }
  },
  modal: {
    closeButton: 'Zamknij',
    confirmButton: 'Rozumiem'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Płatność cykliczna',
        description: 'Podaj dane karty, której użyjesz do płatności. Z karty będą później pobierane płatności automatyczne.',
        labelInitWithRefund: 'Płatność weryfikacyjna',
        labelInitWithRefundOrange: 'Dodaj kartę płatniczą',
        labelInitWithPayment: 'Płatność kartą',
        descriptionInitWithRefund: 'Podaj dane karty, którą chcesz zweryfikować do przyszłych płatności. W&nbsp;kolejnym kroku pobierzemy złotówkę z podanej karty, żeby sprawdzić poprawność danych. Po weryfikacji zwrócimy kwotę.',
        descriptionInitWithPayment: 'Podaj dane karty, którą wykonasz transakcję oraz jednocześnie zweryfikujesz ją&nbsp;do&nbsp;przyszłych płatności.'
      },
      blik: {
        label: 'BLIK',
        description: 'Płać natychmiast wpisując kod BLIK, bez logowania i rejestracji'
      },
      card: {
        label: 'Płatność kartą',
        description: 'Zapłać swoją kartą płatniczą'
      },
      pbl: {
        label: 'Przelew internetowy',
        description: 'Wybierz bank, z którego chcesz zlecić płatność',
        descriptionVerify: 'Weryfikacja tożsamości on-line',
        descriptionServiceVerify: 'Zaznacz bank, dzięki któremu dokonasz weryfikacji serwisu on-line'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Płatność aplikacją Visa Mobile'
      },
      wallet: {
        label: 'Wirtualny portfel',
        description: 'Zapłać bez konieczności logowania się do bankowości internetowej'
      },
      installments: {
        label: 'Rozłóż na raty',
        description: 'Zapłać za zakupy przy użyciu dogodnych rat'
      },
      alior: {
        descriptionInfo1: 'Zapłać za zakupy przy użyciu dogodnych rat',
        descriptionInfo2: '(sprawdź szczegóły)',
        aliorModal: {
          text1: 'W zależności od ustawień serwisu w którym dokonujesz zakupu, masz możliwość zakupu produktów na raty w następujących wariantach:',
          text2: '<ul><li>10 rat 0%</li><li>20 rat 0%</li><li>od 3 do 48 rat z kosztem miesięcznym 1% (zgodnie z przykładem reprezentatywnym podanym dla danej transakcji)</li></ul>'
        }
      },
      otp: {
        label: 'Kup teraz, zapłać później',
        description: 'Kup teraz zapłać później',
        descriptionInfo1Mwf: 'Zapłać później za rachunek - jednorazowo do 45 dni lub w kilku równych ratach',
        descriptionInfo1Ecommerce: 'Zapłać później - jednorazowo do 45 dni lub w kilku równych ratach',
        descriptionInfo2Mwf: 'Informacje o pośredniku kredytowym i kosztach',
        descriptionInfo2Ecommerce: 'Informacje o kosztach',
        labelPayka: 'Payka',
        descriptionPayka: ' <a href="" id="paykaShowInModal"></a>',
        paykaModalMwf: {
          header1: 'Informacja o pośredniku kredytowym',
          text1: 'Autopay S.A. z siedzibą w Sopocie działa jako pośrednik kredytowy Paytree S.A. z siedzibą w Gdańsku (Kredytodawca), uprawniony do prezentowania klientom oferty kredytowej oraz przekierowywania do serwisu internetowego Kredytodawcy, w tym wniosku kredytowego. Szczegóły oferty na: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Dane dotyczące kosztu',
          text2: 'Zapłać później - jednorazowo do 45 dni lub kilku równych ratach. Dane dotyczące kosztu dla przykładu reprezyntatywnego: Spłata do 45 dni RRSO 0%; Spłata ratalna RRSO 86,83%. Szczegóły oferty na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Dane dotyczące kosztu',
          text2: 'Zapłać później - jednorazowo do 45 dni lub kilku równych ratach. Dane dotyczące kosztu dla przykładu reprezyntatywnego: Spłata do 45 dni RRSO 0%; Spłata ratalna RRSO 86,83%. Szczegóły oferty na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        }
      },
      blikPayLater: {
        label: 'BLIK Płacę Później',
        description: 'Usługa dostępna dla klientów Banku Millennium i VeloBanku',
        modal: {
          header1: 'Kupuj teraz, płać&nbsp;w&nbsp;ciągu&nbsp;30&nbsp;dni',
          header2: 'Kod BLIK',
          text2: 'Wpisujesz kod BLIK, a my proponujemy Ci do 4 000 zł limitu na zakupy',
          header3: 'Kupujesz produkty, za które płacisz później',
          text3: 'Masz czas, aby sprawdzić czy wszystko Ci odpowiada',
          header4: 'Płacisz w ciągu 30 dni bez kosztów',
          text4: 'Możesz też zwrócić zakupy w terminie przewidzianym przez sklep'
        }
      },
      companyPayments: {
        label: 'Płatności firmowe',
        description: 'Przesuń termin płatności za zakupy firmowe o 30 dni'
      },
      transfer: {
        label: 'Dane do przelewu',
        description: 'Zleć przelew wykorzystując podane dane'
      },
      other: {
        label: 'Inne',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Przepraszamy. Aktualnie nie możemy zrealizować Twojego przelewu. Wybrany bank jest w tej chwili niedostępny.',
        blockMultipleTransactions: 'Przepraszamy. Transakcja już istnieje i oczekuje na wpłatę.',
        blockPaidTransactions: 'Przepraszamy. Transakcja została już opłacona. Nie możesz wykonać płatności ponownie.',
        canceledTransactions: 'Transakcja, którą próbujesz opłacić została anulowana i nie jest dostępna. Prosimy o rozpoczęcie nowej transakcji.',
        declined: 'Odmowa autoryzacji.',
        generalError: 'Wystąpił chwilowy problem z połączeniem. W tej chwili nie możemy zrealizować Twojego przelewu. Zapraszamy później.',
        insufficientStartAmount: 'Przepraszamy. Limit kwoty przelewu został przekroczony.',
        internalServerError: 'Upsss.. nie powiodło się :(. Pracujemy nad rozwiązaniem problemu. Spróbuj ponownie później.',
        nonAccountedLimitExceeded: 'Płatność nie może być zrealizowana, ponieważ sprzedawca osiągnął miesięczny limit sprzedaży.',
        outdatedError: 'Czas na dokończenie płatności minął.',
        paid: 'Transakcja zakończona pomyślnie.',
        paywayLoaded: 'Załadowano stronę szybkiego przelewu',
        paywaylistLoaded: 'Załadowano stronę wyboru metod płatności'
      }
    }
  },
  maintenance: {
    text1: 'Trwają noworoczne porządki w bramce płatniczej Autopay, w związku z czym zlecenie płatności w tym momencie jest niemożliwe. Przepraszamy za utrudnienia.',
    text2: 'Bramka będzie działać ponownie już 16 stycznia o 10:00.',
    text3: 'Dziękujemy za wyrozumiałość.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Podaj imię i nazwisko',
      flowLabel: 'Imię i nazwisko',
      placeholder: 'Podaj imię i nazwisko'
    },
    nip: {
      label: 'Podaj numer NIP',
      flowLabel: 'NIP',
      placeholder: 'Podaj numer NIP'
    },
    customerEmail: {
      label: 'Podaj adres e-mail',
      flowLabel: 'E-mail',
      placeholder: 'Podaj adres e-mail'
    }
  }
}
